import React from 'react';
import UserListComponent from 'src/components/UserList';
import defaultStyles from '../styles.module.css';

const UserListPage: React.FC<{ admins?: boolean }> = ({ admins = false }) => {
  return (
    <>
      <div className={defaultStyles.contentStyles}>
        <UserListComponent admins={admins} />
      </div>
    </>
  );
};

export default UserListPage;
