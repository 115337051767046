import React from 'react';
import styles from './styles.module.css';
import {
  Grid,
  Typography,
  Paper,
  Modal,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { resetPassword } from 'src/services/authService';

const paperStyle = {
  padding: 10,
  width: 542,
  margin: '10px auto',
  maxHeight: 750,
  overflow: 'auto'
};

const ChangePasswordComponent = (props: any) => {
  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      toast.success('Uspesno ste resetovali lozinku!');
      props.handleCloseModule();
    },
    onError: () => {
      toast.error('Resetovanje lozinke neuspesna!');
    }
  });
  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleCloseModule}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={10} style={paperStyle}>
        <Grid container>
          <Grid item className={styles.loginItem}>
            <h2>
              {`Resetujte lozinku za korisnika: ${
                props.editedUser?.firstName ?? ''
              } ${props.editedUser?.lastName ?? ''}`}
            </h2>
            <Typography>
              Da li ste sigurni da želite da resetujete korisnikovu lozinku ?
            </Typography>
          </Grid>

          <Grid
            item
            className={styles.loginItem}
            sx={{ display: 'flex', gap: '1rem' }}
          >
            <Button
              className={styles.buttonBlock}
              variant="outlined"
              onClick={() => props.handleCloseModule()}
            >
              Otkaži
            </Button>
            <LoadingButton
              loading={isLoading}
              type="submit"
              onClick={() => mutate(props.editedUser.id)}
              color="primary"
              variant="contained"
              className={styles.buttonBlock}
              fullWidth
            >
              Resetuj lozinku
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ChangePasswordComponent;
