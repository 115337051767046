export const sortOptions = [
  { value: 'firstName', label: 'imenu (a-z) ' },
  { value: '-firstName', label: 'imenu (z-a) ' },
  { value: 'lastName', label: 'prezimenu (a-z) ' },
  { value: '-lastName', label: 'prezimenu (z-a) ' },
  { value: 'email', label: 'email adresi (a-z) ' },
  { value: '-email', label: 'email adresi (z-a) ' },
  { value: 'company.name', label: 'imenu kompanije (a-z) ' },
  { value: '-company.name', label: 'imenu kompanije (z-a) ' }
];
