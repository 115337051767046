import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Company } from 'src/models/company';
import * as AdminService from '../../services/adminService';
import { SingleUser } from 'src/models/singleUser';
import { TableOptionsState } from 'src/utils/useTablePagination';

const SLICE_NAME = 'admin';

const initialState = {
  pending: false,
  allCompanies: {
    data: [] as Company[],
    totalCount: 0
  },
  allSingleUsers: {
    data: [] as SingleUser[],
    totalCount: 0
  },
  documentTemplates: {
    data: [],
    totalCount: 0
  }
};

export const deleteCompany = createAsyncThunk(
  'admin/company/delete',
  async (id: number) => {
    const res = await AdminService.deleteCompany(id);
    return res;
  }
);
export const getAllCompanies = createAsyncThunk(
  'admin/company/get',
  async ({ page, pageSize, sortBy, search, industryId, licenseId }: any) => {
    const res = await AdminService.getAll(
      page,
      pageSize,
      sortBy,
      search,
      industryId,
      licenseId
    );
    return res;
  }
);
export const addCompany = createAsyncThunk(
  'admin/copmany/post',
  async (data: Company) => {
    const res = await AdminService.add(data);
    return res;
  }
);
export const editCompany = createAsyncThunk(
  'admin/copmany/patch',
  async (data: Company) => {
    const res = await AdminService.edit(data);
    return res;
  }
);
export const getAdminTemplateDocuments = createAsyncThunk(
  'admin/list-document-templates/get',
  async (data: { options: TableOptionsState; folder: number }) => {
    const res = await AdminService.getAdminTemplateDocuments(data);
    return res;
  }
);

export const addTemplateDocument = createAsyncThunk(
  'admin/add-template-document/post',
  async (data: any) => {
    const res = await AdminService.addTemplateDocument(data);
    return res;
  }
);

export const editTemplateDocument = createAsyncThunk(
  'admin/edit-template-document/put',
  async ({ data, id }: { data: any; id: number }) => {
    const res = await AdminService.editTemplateDocument(data, id);
    return res;
  }
);

export const deleteTemplateDocument = createAsyncThunk(
  'admin/delete-template-document/delete',
  async (id: number) => {
    await AdminService.deleteTemplateDocument(id);
  }
);

export const getAllSingleUsers = createAsyncThunk(
  'admin/single-user/get',
  async ({ page, pageSize, sortBy, search, licenseId }: any) => {
    const res = await AdminService.getAllSingleUsers(
      page,
      pageSize,
      sortBy,
      search,
      licenseId
    );
    return res;
  }
);

export const editSingleUser = createAsyncThunk(
  'admin/single-user/patch',
  async (data: SingleUser) => {
    const res = await AdminService.editSingle(data);
    return res;
  }
);

export const changeApiStatus = createAsyncThunk(
  'admin/company/status/post',
  async (data: any) => {
    const res = await AdminService.changeApiStatus(data);
    return res;
  }
);

export const lockUnlockCompany = createAsyncThunk(
  'admin/company/status/post',
  async (data: any) => {
    const res = await AdminService.lockUnlockCompany(data);
    return res;
  }
);

export const getApiKey = createAsyncThunk(
  'admin/company/api/get',
  async (data: any) => AdminService.getApiKeyAdmin(data)
);

export const renewApiKey = createAsyncThunk(
  'admin/company/api/renew/post',
  async (data: any) => AdminService.renewApiKey(data)
);

export const lockUnlockSingleUser = createAsyncThunk(
  'admin/single-user/patch',
  async (data: SingleUser) => {
    const body = {
      id: data.id,
      address: data.address,
      firstName: data.firstName,
      lastName: data.lastName,
      jmbg: data.jmbg,
      licenseName: data.licenseName,
      licensePlainId: data.licensePlainId,
      locked: !data.locked
    };
    const res = await AdminService.editSingle(body);
    return res;
  }
);

const pendingMethod = (state) => {
  state.pending = true;
};

const adminSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [deleteCompany.pending.toString()]: pendingMethod,
    [addCompany.pending.toString()]: pendingMethod,
    [editCompany.pending.toString()]: pendingMethod,
    [editCompany.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [editCompany.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAdminTemplateDocuments.pending.toString()]: pendingMethod,
    [getAdminTemplateDocuments.fulfilled.toString()]: (state, action) => {
      state.documentTemplates = action.payload;
      state.pending = false;
    },
    [getAdminTemplateDocuments.rejected.toString()]: (state) => {
      state.pending = false;
    },
    [addTemplateDocument.pending.toString()]: pendingMethod,
    [addTemplateDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [addTemplateDocument.rejected.toString()]: (state) => {
      state.pending = false;
    },
    [editTemplateDocument.pending.toString()]: pendingMethod,
    [editTemplateDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [editTemplateDocument.rejected.toString()]: (state) => {
      state.pending = false;
    },
    [deleteTemplateDocument.pending.toString()]: pendingMethod,
    [deleteTemplateDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteTemplateDocument.rejected.toString()]: (state) => {
      state.pending = false;
    },
    [getAllCompanies.pending.toString()]: pendingMethod,
    [getAllCompanies.fulfilled.toString()]: (state, action) => {
      state.allCompanies = action.payload;
      state.pending = false;
    },
    [getAllCompanies.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addCompany.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addCompany.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteCompany.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteCompany.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllSingleUsers.pending.toString()]: pendingMethod,
    [getAllSingleUsers.fulfilled.toString()]: (state, action) => {
      state.allSingleUsers = action.payload;
      state.pending = false;
    },
    [getAllSingleUsers.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [editSingleUser.pending.toString()]: pendingMethod,
    [editSingleUser.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [editSingleUser.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [changeApiStatus.pending.toString()]: pendingMethod,
    [changeApiStatus.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [changeApiStatus.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [lockUnlockCompany.pending.toString()]: pendingMethod,
    [lockUnlockCompany.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [lockUnlockCompany.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getApiKey.pending.toString()]: pendingMethod,
    [getApiKey.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getApiKey.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [renewApiKey.pending.toString()]: pendingMethod,
    [renewApiKey.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [renewApiKey.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [lockUnlockSingleUser.pending.toString()]: pendingMethod,
    [lockUnlockSingleUser.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [lockUnlockSingleUser.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    }
  }
});

const { reducer } = adminSlice;
export default reducer;

const getAppState = (state) => state[SLICE_NAME];

export const adminSelector = {
  pending: (rootState) => {
    const appState = getAppState(rootState);
    return appState.pending;
  },
  allCompanies: (rootState) => {
    const appState = getAppState(rootState);
    return appState.allCompanies;
  },
  documentTemplates: (rootState) => {
    const appState = getAppState(rootState);
    return appState.documentTemplates;
  },
  allSingleUsers: (rootState) => {
    const appState = getAppState(rootState);
    return appState.allSingleUsers;
  }
};
