import {
  RegisterLicenseType,
  RegisterCompanyType,
  RegisterUserType
} from 'src/content/modules/Register/types';
import axiosInstance from 'src/utils/axiosInstance';
import { TableOptionsState } from 'src/utils/useTablePagination';

export const editCompanyDocument = async (data: any, id: number) => {
  const response = await axiosInstance.put(
    `/documents/update-document-company/${id}`,
    data
  );
  return response.data;
};

export const deleteCompanyDocument = async (id: number) => {
  await axiosInstance.delete(`/documents/delete-document-company/${id}`);
};

export const getCompanyInternalDocuments = async ({
  options
}: {
  options: TableOptionsState;
}) => {
  const response = await axiosInstance.get(
    `/documents/list-documents-company?page=${options.pagination.page}&pageSize=${options.pagination.pageSize}`
  );
  return { data: response.data.content, totalCount: response.data.total };
};

export const addCompanyInternalDocuments = async (data) => {
  const response = await axiosInstance.post(
    `/documents/upload-document-company`,
    data
  );
  return response.data;
};

export const getCompanyTemplateDocuments = async ({
  options,
  folder
}: {
  options: TableOptionsState;
  folder: number;
}) => {
  const response = await axiosInstance.get(
    `/documents/list-document-templates?page=${options.pagination.page}&pageSize=${options.pagination.pageSize}&folder=${folder}`
  );
  return { data: response.data.content, totalCount: response.data.total };
};

export const getApiKey = async () => {
  const { data } = await axiosInstance.get('/company/api-key');
  return data;
};
export const enableApiKey = async (data: { active: number }) => {
  const response = await axiosInstance.post('/company/api-key', data);
  return response.data;
};

export const getAllIndustries = async () => {
  const { data } = await axiosInstance.get('/common/industry');
  return data;
};
export const getAllLicenses = async () => {
  const { data } = await axiosInstance.get('/common/license');
  return data;
};
export const getAllSingleLicenses = async () => {
  const { data } = await axiosInstance.get('/common/license-single-user');
  return data;
};
export const signUpCompany = async (
  data: RegisterCompanyType & RegisterUserType & RegisterLicenseType
) => {
  const response = await axiosInstance.post('/registration-company', data);
  return response.data;
};

export const renewCompanyApiKey = async () => {
  const { data } = await axiosInstance.get('/company/api-key/renew');
  return data;
};
