import { TokenData } from 'src/models/user';
import axiosInstance from '../utils/axiosInstance';
import jwt_decode from 'jwt-decode';
import { Role } from 'src/models/role';

export const login = async (data: any) => {
  const response = await axiosInstance.post('/authenticate', data);
  return response.data;
};

export const changePassword = async (data) => {
  const response = await axiosInstance.post('/changeItsPassword', data);
  return response.data;
};

export const resetPassword = async (id: number) => {
  const response = await axiosInstance.post('/admin/changePasswordReset', {
    id
  });
  return response.data;
};

export const getRole = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const currentUser: TokenData = jwt_decode(token);
    return currentUser.type ? (currentUser.type as Role) : null;
  }
  return null;
};
export const getEmail = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const curentUser: TokenData = jwt_decode(token);
    return curentUser.sub ?? null;
  }
  return null;
};

export const getFullName = () => {
  const token = localStorage.getItem('token');

  if (token) {
    const curentUser: TokenData = jwt_decode(token);
    return curentUser.fullName ?? null;
  }
  return null;
};

export const getAccList = () => {
  const token = localStorage.getItem('token');

  if (token) {
    const curentUser: TokenData = jwt_decode(token);
    return curentUser.acc ?? null;
  }
  return [];
};
