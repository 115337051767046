import { Box, CardActionArea, CardMedia, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/redux';
import { setFilterDate } from 'src/redux/slices/historySlice';

const LogoWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 150px;
        height: 38px;
        margin-top: 4px;
        transform: scale(.8);
`
);
const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
  margin-top:6px;
  display:flex;
  flex-direction: column;
        padding-left: ${theme.spacing(1)};
`
);

function Logo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <LogoWrapper>
      <LogoSignWrapper>
        <CardActionArea>
          <CardMedia
            onClick={(e) => {
              dispatch(setFilterDate({}));
              navigate('/');
            }}
            component="img"
            height="50"
            image="/static/images/overview/Logo-with-text.png"
            alt="Paella dish"
          />
        </CardActionArea>
      </LogoSignWrapper>
      <Hidden smDown>
        <LogoTextWrapper></LogoTextWrapper>
      </Hidden>
    </LogoWrapper>
  );
}

export default Logo;
