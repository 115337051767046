// PrivateRoute.tsx in v6
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { authSelector } from 'src/redux/slices/authSlice';
import { getAccList } from '../services/authService';

const PrivateRoute = ({
  children,
  acc
}: {
  children: JSX.Element;
  acc: string[];
}) => {
  let location = useLocation();
  const { currentUser, loading } = useSelector(authSelector.auth);

  if (loading) {
    return <p className="container">Checking auth..</p>;
  }

  const accList = getAccList();

  const userHasRequiredAcc =
    currentUser && accList.some(e => acc.includes(e)) ? true : false;

  if (!localStorage.getItem('token')) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (localStorage.getItem('token') && !userHasRequiredAcc) {
    return accList.includes('admin') ? (
      <Navigate to="/admin" state={{ from: location }} />
    ) : (
      <Navigate to="/" state={{ from: location }} />
    ); // build your won access denied page (sth like 404)
  }

  return children;
};
export default PrivateRoute;
