import { getAccList } from 'src/services/authService';
import { ChangePasswordType } from 'src/models/changePassword';
import { User } from 'src/models/user';
import axiosInstance from 'src/utils/axiosInstance';

export const allUsers = async (
  page: number,
  pageSize: number,
  sortBy: string,
  search: string,
  admins: boolean
) => {
  const acc = getAccList();
  const prefix = acc.includes('admin') ? 'admin/all' : 'user';
  const response = await axiosInstance.get(
    `${prefix}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}${
      search ? '&search=' + search : ''
    }`
  );
  return {
    data: response.data.content,
    totalCount: response.data.total,
    remainingLicenses: response.data.remainingLicenses
  };
};

export const register = async (data: User) => {
  const acc = getAccList();
  const response = await axiosInstance.post(
    acc.includes('admin') ? '/admin/company/user' : '/user',
    data
  );
  return response.data;
};
export const editUser = async (data: any) => {
  const acc = getAccList();
  const response = await axiosInstance.patch(
    acc.includes('admin') ? '/admin/company/user' : '/user',
    data
  );
  return response.data;
};
export const deleteUser = async (id: number) => {
  const acc = getAccList();
  const response = await axiosInstance.delete(
    `${acc.includes('admin') ? '/admin/company/user' : '/user/'}${id}`
  );
  return response.status;
};

export const changePassword = async (data: ChangePasswordType) => {
  const response = await axiosInstance.post(`/changePassword`, data);
  return response.status;
};
