import React, { useEffect, useState } from 'react';
import {
  DialogActions,
  Button,
  DialogContent,
  Grid,
  Dialog,
  DialogTitle,
  TextField,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';
import styles from '../../../../components/AddUser/styles.module.css';
import {
  enableCompanyApiKey,
  getCompanyApiKey,
  renewCompanyApiKey
} from '../../../../redux/slices/companySlice';
import { useAppDispatch } from '../../../../redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';

const ApiSettings: React.FC<{ show: boolean; close: () => void }> = ({
  show,
  close
}) => {
  const dispatch = useAppDispatch();
  const [apiKey, setApiKey] = useState('');
  const [apiEnabled, setApiEnabled] = useState(0);
  const [apiLocked, setApiLocked] = useState(1);

  const handleRenew = async () => {
    const response = await dispatch(renewCompanyApiKey());
    if (response.meta.requestStatus === 'fulfilled') {
      toast.success('Novi API ključ uspešno generisan.');
      setApiKey(response.payload);
    }
  };

  useEffect(() => {
    if (show) {
      dispatch(getCompanyApiKey()).then((data) => {
        setApiKey(data.payload.key);
        setApiEnabled(data.payload.active);
        setApiLocked(data.payload.locked);
      });
    }
  }, [show]);

  return (
    <Dialog open={show} onClose={close}>
      <DialogTitle>API Podešavanja</DialogTitle>
      <DialogContent
        dividers
        sx={{
          width: {
            md: '550px'
          }
        }}
      >
        {apiLocked ?
          (
            <Grid container>
              <Grid item className={styles.apiTextFieldWrapper}>
                <Typography variant='h5'>
                  API je zaključan.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item className={styles.apiTextFieldWrapper}>
                <TextField
                  name="apiKey"
                  label={!apiKey ? 'API nije aktivan' : 'Api Key'}
                  placeholder="API key"
                  type="text"
                  disabled
                  fullWidth
                  value={apiKey}
                />
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(apiKey);
                    toast.success('API Key je kopiran.');
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Grid>
              <FormGroup sx={{ margin: 'auto' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={apiEnabled === 1 ? true : false}
                      name="enableApi"
                      color={'success'}
                      onChange={({ target }) => {
                        dispatch(
                          enableCompanyApiKey({ active: target.checked ? 1 : 0 })
                        ).then((data) => {
                          setApiKey(data.payload.key);
                          setApiEnabled(data.payload.active);
                        });
                      }}
                    />
                  }
                  label="Omogući"
                />
              </FormGroup>
              <Grid item className={styles.apiTextFieldWrapper}>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  fullWidth
                  onClick={() => handleRenew()}
                >
                  Generiši novi API ključ
                </Button>
              </Grid>
            </Grid>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          type="button"
          onClick={close}
        >
          Izadji
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiSettings;
