import { UpdateDocument } from 'src/models/client';
import AxiosInstance from 'src/utils/axiosInstance';
import axiosInstance from 'src/utils/axiosInstance';
import { TableOptionsState } from 'src/utils/useTablePagination';

export const allPersonClients = async ({
  options,
  clientType,
  search
}: {
  options: TableOptionsState;
  clientType: number;
  search: string;
}) => {
  const response = await axiosInstance.get(
    `clients/clients-list?page=${options.pagination.page}&pageSize=${
      options.pagination.pageSize
    }&clientType=${clientType}${search ? '&search=' + search : ''}`
  );
  return { data: response.data.content, totalCount: response.data.total };
};

export const allCompanyClients = async ({
  options,
  clientType,
  search
}: {
  options: TableOptionsState;
  clientType: number;
  search: string;
}) => {
  const response = await axiosInstance.get(
    `clients/clients-list?page=${options.pagination.page}&pageSize=${
      options.pagination.pageSize
    }&clientType=${clientType}${search ? '&search=' + search : ''}`
  );
  return { data: response.data.content, totalCount: response.data.total }; //&sortBy=${sortBy}  &sortBy=id
};

export const getClientsDropdown = async () => {
  try {
    const response = await axiosInstance.get('/clients/clients-dropdown');
    return response.data;
  } catch (error) {
    console.error('Error fetching clients dropdown:', error);
    throw error;
  }
};

export const getClientsSearchHistory = async (clientId: number) => {
  const response = await axiosInstance.get(
    `/clients/get-client-search-history/${clientId}`
  );
  return response.data;
};

export const getClientsConnectedEntities = async (clientId: number) => {
  const response = await axiosInstance.get(
    `/clients/get-client-connected-entities/${clientId}`
  );
  return response.data;
};

export const changeIsSanctioned = async (data: {
  id: number;
  isSanctioned: number;
}) => {
  const response = await axiosInstance.put(
    '/clients/client-search-history-change-is-sanctioned',
    data
  );

  return response.data;
};

export const addClient = async (data: any) => {
  const response = await axiosInstance.post('/clients/create-client', data);
  return response.data;
};

export const updateClient = async (data: any, id: number) => {
  const response = await axiosInstance.put(
    `/clients/update-client/${id}`,
    data
  );
  return response.data;
};

export const getClientPerson = async (clientId: number) => {
  const response = await axiosInstance.get(
    `/clients/client?clientId=${clientId}`
  );
  return response.data;
};
export const getClientCompany = async (clientId: number) => {
  const response = await axiosInstance.get(
    `/clients/client?clientId=${clientId}`
  );
  return response.data;
};

export const addClientConnectedEntities = async (data: any) => {
  const response = await axiosInstance.post(
    '/clients/add-client-connected-entity',
    data
  );
  return response.data;
};

export const deleteClientConnectedEntity = async (id: number) => {
  const response = await AxiosInstance.delete(
    `/clients/delete-client-connected-entity/${id}`
  );
  return response;
};

export const addClientDocument = async (id: string, data: any) => {
  const response = await axiosInstance.post(
    `/client/doc-client/location/${id}`,
    data
  );
  return response.data;
};

export const addClientAttachments = async (data) => {
  const response = await axiosInstance.post(`/clients/add-document`, data);
  return response.data;
};

export const deleteClientDocument = async (clientDocumentId: number) => {
  const response = await AxiosInstance.delete(
    `/clients/delete-client-document/${clientDocumentId}`
  );
  return response;
};

export const updateClientDocument = async (
  clientDocumentId: number,
  data: UpdateDocument
) => {
  const response = await AxiosInstance.put(
    `/clients/update-client-document/${clientDocumentId}`,
    data
  );
  return response.data;
};

export const updateClientConnectedEntity = async (id: number, data: any) => {
  const response = await AxiosInstance.put(
    `/clients/update-client-connected-entity/${id}`,
    data
  );
  return response.data;
};

export const startSearchClient = async (data: any) => {
  const response = await AxiosInstance.post(
    '/clients/add-client-search-history',
    data
  );
  return response.data;
};

export const addRiskMatrix = async (data: any) => {
  const response = await AxiosInstance.post(
    '/clients/add-client-risk-matrix',
    data
  );
  return response.data;
};

export const updateRiskMatrix = async ({
  data,
  id
}: {
  data: any;
  id: number;
}) => {
  const response = await AxiosInstance.put(
    `/clients/update-client-risk-matrix/${id}`,
    data
  );
  return response.data;
};

export const getRiskMatrix = async (id: number) => {
  const response = await AxiosInstance.get(
    `/clients/get-client-risk-matrix/${id}`
  );
  return response.data;
};
