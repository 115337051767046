import React, { useEffect } from 'react';
import {
  DialogActions,
  Button,
  DialogContent,
  Grid,
  Dialog,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../../../../components/AddUser/styles.module.css';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { changePassword } from 'src/services/authService';

export const requiredErrorMsg = 'Ovo polje je obavezno';

const validation = yup.object().shape({
  oldPassword: yup
    .string()
    .min(8, '*Lozinka mora da sadrži minimum 8 znakova')
    .required(requiredErrorMsg),
  newPassword: yup
    .string()
    .min(8, '*Lozinka mora da sadrži minimum 8 znakova')
    .required(requiredErrorMsg),
  confirmNewPassword: yup
    .string()
    .required(requiredErrorMsg)
    .oneOf([yup.ref('newPassword'), null], 'Sifra mora biti ista')
});

const ChangePassword: React.FC<{ show: boolean; close: () => void }> = ({
  show,
  close
}) => {
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    onSubmit: (values) => mutateAsync(values),
    validationSchema: validation
  });
  const { mutateAsync } = useMutation(changePassword, {
    onSuccess: () => {
      toast.success('Uspešno ste promenili lozinku!');
      close();
    },
    onError: () => {
      toast.error('Promena lozinke neuspešna!');
    }
  });
  useEffect(() => {
    formik.resetForm();
  }, [show]);
  return (
    <Dialog open={show} onClose={close}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Promena lozinke</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item className={styles.loginItem}>
              <TextField
                error={formik.errors.oldPassword != null}
                name="oldPassword"
                label="Važeća Lozinka"
                placeholder="Unesi lozinku"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
              />{' '}
              {formik.errors.oldPassword ? (
                <Typography color="text.secondary" className={styles.textForm}>
                  <i>{formik.errors.oldPassword}</i>
                </Typography>
              ) : (
                formik.errors.oldPassword && (
                  <Typography color="error" className={styles.textForm}>
                    <i>{formik.errors.oldPassword}</i>
                  </Typography>
                )
              )}
            </Grid>
            <Grid item className={styles.loginItem}>
              <TextField
                error={formik.errors.newPassword != null}
                name="newPassword"
                label="Nova lozinka"
                placeholder="Unesi lonovo lozinku"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
              />
              {formik.errors.newPassword ? (
                <Typography color="text.secondary" className={styles.textForm}>
                  <i>{formik.errors.newPassword}</i>
                </Typography>
              ) : (
                formik.errors.newPassword && (
                  <Typography color="error" className={styles.textForm}>
                    <i>{formik.errors.newPassword}</i>
                  </Typography>
                )
              )}
            </Grid>
            <Grid item className={styles.loginItem}>
              <TextField
                error={formik.errors.confirmNewPassword != null}
                name="confirmNewPassword"
                label="Potvrda lozinke"
                placeholder="Unesi lonovo lozinku"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmNewPassword}
              />{' '}
              {formik.errors.confirmNewPassword ? (
                <Typography color="text.secondary" className={styles.textForm}>
                  <i>{formik.errors.confirmNewPassword}</i>
                </Typography>
              ) : (
                formik.errors.confirmNewPassword && (
                  <Typography color="error" className={styles.textForm}>
                    <i>{formik.errors.confirmNewPassword}</i>
                  </Typography>
                )
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={close}
          >
            Izadji
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Sacuvaj
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePassword;
