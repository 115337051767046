import { SearchEntity, SearchData } from 'src/models/searchEntity';
import axiosInstance from 'src/utils/axiosInstance';

export const searchSanctionList = async (data: SearchEntity) => {
  return axiosInstance.post('/search', data).then(response => {
    if (response.status === 200) {
      return response.data;
    } else {
      const errorMessage = response.data && response.data.message ? response.data.message : 'Nepoznata greška';
      throw new Error(errorMessage);
    }
  })
  .catch(error => {
    throw error;
  });
};

export const addClientSearchHistory = async (data) => {
  return axiosInstance.post('/clients/add-client-search-history', data).then(response => {
    if (response.status === 200){
      return response.data;
    } else {
      const errorMessage = response.data && response.data.messasge ? response.data.message: 'Nepoznata greška';
      throw new Error(errorMessage);
    }
  })
  .catch(error => {
    throw error;
  });
};