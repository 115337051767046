import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';
import { SearchHistoryEntity } from 'src/models/searchHistoryEntity';
import { getAccList } from 'src/services/authService';
import * as HistoryService from 'src/services/historyService';

const SLICE_NAME = 'history';

const initialState = {
  searchHistory: {
    data: [] as SearchHistoryEntity[],
    totalCount: 0
  },
  sideBarFields: {
    searchYears: {}
  },
  filterDate: {},
  userOptions: [],
  pending: false
};

export const getSearchHistory = createAsyncThunk(
  'history/list/get',
  async (data: any) => {
    const res = await HistoryService.getSearchHistory(data);
    return res;
  }
);
export const getSearchHistoryForOwner = createAsyncThunk(
  'history/list/company/get',
  async (data: any) => {
    const res = await HistoryService.getSearchHistoryOwner(data);
    return res;
  }
);
export const getSingleSearchHistory = createAsyncThunk(
  'history/get',
  async(searchHistoryId: number) => {
    const res = await HistoryService.getSingleSearchHistory(searchHistoryId);
    return res;
  }
);
export const saveSearch = createAsyncThunk(
  'history/post',
  async (data: any) => {
    const res = await HistoryService.saveSearch(data);
    return res;
  }
);
export const getSideBarFields = createAsyncThunk(
  'history/sideBar/get',
  async () => {
    const res = await HistoryService.getSideBar();
    return res;
  }
);

export const getUserOptions = createAsyncThunk(
  'history/users/get',
  async () => {
    const res = await HistoryService.getUsers();
    return res;
  }
);

const acc = getAccList();
const hasSearch = acc.includes('search');

const historySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFilterDate: (state, action) => {
      if (hasSearch) {
        state.filterDate = action.payload;
      }
    }
  },
  extraReducers: {
    [getSearchHistory.pending.toString()]: (state, action) => {
      state.pending = true;
    },
    [getSearchHistory.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getSearchHistory.fulfilled.toString()]: (state, action) => {
      state.pending = false;
      state.searchHistory = action.payload;
    },
    [getSearchHistoryForOwner.pending.toString()]: (state, action) => {
      state.pending = true;
    },
    [getSearchHistoryForOwner.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getSearchHistoryForOwner.fulfilled.toString()]: (state, action) => {
      state.pending = false;
      state.searchHistory = action.payload;
    },
    [getSingleSearchHistory.pending.toString()]: (state, action) => {
      state.pending = true;
    },
    [getSingleSearchHistory.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getSingleSearchHistory.fulfilled.toString()]: (state, action) => {
      state.pending = false;
      state.searchHistory = action.payload;
    },
    [saveSearch.pending.toString()]: (state, action) => {
      state.pending = true;
    },
    [saveSearch.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [saveSearch.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getSideBarFields.pending.toString()]: (state, action) => {
      state.pending = true;
    },
    [getSideBarFields.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getSideBarFields.fulfilled.toString()]: (state, action) => {
      state.pending = false;
      state.sideBarFields = action.payload;
    },
    [getUserOptions.pending.toString()]: (state, action) => {
      state.pending = true;
    },
    [getUserOptions.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getUserOptions.fulfilled.toString()]: (state, action) => {
      state.pending = false;
      state.userOptions = action.payload;
    }
  }
});
export const { setFilterDate } = historySlice.actions;
const { reducer } = historySlice;
export default reducer;

const getAppState = (state) => state[SLICE_NAME];

export const historySelector = {
  pending: (rootState) => {
    const appState = getAppState(rootState);
    return appState.pending;
  },
  searchHistory: (rootState) => {
    const appState = getAppState(rootState);
    return appState.searchHistory;
  },
  sideBarFields: (rootState) => {
    const appState = getAppState(rootState);
    return appState.sideBarFields;
  },
  filterDate: (rootState) => {
    const appState = getAppState(rootState);
    return appState.filterDate;
  },
  userOptions: (rootState) => {
    const appState = getAppState(rootState);
    return appState.userOptions;
  }
};
