import { SearchHistoryEntity } from 'src/models/searchHistoryEntity';
import axiosInstance from 'src/utils/axiosInstance';

export const getSearchHistory = async ({
  page,
  pageSize,
  fromDate,
  toDate,
  entity,
  sortBy,
  sanctioned
}) => {
  const params = {
    page,
    pageSize,
    fromDate,
    toDate,
    entity,
    sortBy,
    sanctioned
  };

  const response = await axiosInstance.get(`/history/list`, { params });

  return {
    data: response.data.content,
    totalCount: response.data.total
  };
};

export const getSearchHistoryOwner = async ({
  page,
  pageSize,
  fromDate,
  toDate,
  entity,
  sortBy,
  sanctioned,
  user
}) => {
  try {
    const params = {
      page,
      pageSize,
      fromDate,
      toDate,
      entity,
      sortBy,
      sanctioned,
      user
    };

    let url = `/history/list/company`;

    const response = await axiosInstance.get(url, { params });

    return {
      data: response.data.content,
      totalCount: response.data.total
    };
  } catch (error) {
    return { data: [], totalCount: 0 };
  }
};

export const getSingleSearchHistory = async (searchHistoryId: number) => {
  const response = await axiosInstance.get(`/history/${searchHistoryId}`);
  return response.data;
};

export const saveSearch = async (data: SearchHistoryEntity) => {
  const response = await axiosInstance.post(`/history`, data);
  return response.data;
};

export const getSideBar = async () => {
  const response = await axiosInstance.get(`/history/dates`);
  return response.data;
};

export const getUsers = async () => {
  const response = await axiosInstance.get(`/history/users`);
  return response.data;
};
