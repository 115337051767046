import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

const DeleteConfirmationComponent = (props: any) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>otkaži</Button>
        <Button
          variant="contained"
          color="error"
          onClick={(e) => props.handleConfirm(props.deletedId)}
          autoFocus
        >
          obriši
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationComponent;
