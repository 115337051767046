import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SearchEntity, SearchData } from 'src/models/searchEntity';
import * as SearchService from '../../services/searchService';

const SLICE_NAME = 'search';

const initialState = {
  searchResults: {
    params: {} as SearchEntity,
    searchDate: '',
    userData: {}
  },
  pending: false
};

export const searchSanctionList = createAsyncThunk(
  'search/post',
  async (data: SearchEntity) => {
    const res = await SearchService.searchSanctionList(data);
    return res;
  }
);

export const addClientSearchHistory = createAsyncThunk(
  '/clients/add-client-search-history/post',
  async (data: SearchData) => {
    const res = await SearchService.addClientSearchHistory(data);
    return res;
  }
); 

const searchSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSearchResults: (state, action) => {
      state.searchResults = {
        params: action.payload.params,
        searchDate: action.payload.searchDate,
        userData: action.payload.userData
      };
    }
  },
  extraReducers: {
    [searchSanctionList.pending.toString()]: (state, action) => {
      state.pending = true;
    },
    [searchSanctionList.rejected.toString()]: (state, action) => {
      state.pending = false;
      state.searchResults = {
        params: {},
        searchDate: '',
        userData: {}
      };
    },
    [searchSanctionList.fulfilled.toString()]: (state, action) => {
      state.pending = false;
      state.searchResults = {
        params: action.payload.searchParams,
        searchDate: action.payload.searchDate,
        userData: action.payload.userData
      };
    }
  }
});

const { reducer } = searchSlice;
export default reducer;

const getAppState = (state) => state[SLICE_NAME];

export const searchSelector = {
  pending: (rootState) => {
    const appState = getAppState(rootState);
    return appState.pending;
  },
  searchResults: (rootState) => {
    const appState = getAppState(rootState);
    return appState.searchResults;
  }
};

export const { setSearchResults } = searchSlice.actions;
