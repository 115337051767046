import { useRoutes } from 'react-router-dom';
import routes from './router/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useAppDispatch } from './redux';
import AxiosInstance from './utils/axiosInstance';
import { logout } from './redux/slices/authSlice';
import { ServerError } from './models/errors/serverError';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();
const App = () => {
  const content = useRoutes(routes);
  const dispatch = useAppDispatch();
  AxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
        const serverError = new ServerError(
          error.response.status,
          error.response.data.message
        );
        return Promise.reject(serverError);
      }
      return Promise.reject(error);
    }
  );
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          {content}
          <Toaster position="bottom-right" />
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
