import {
  Card,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  useTheme,
  TablePagination,
  Box,
  Container,
  Grid,
  Button,
  CardHeader,
  TextField,
  MenuItem,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Chip,
  Badge
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useAppDispatch } from 'src/redux';
import debounce from 'lodash.debounce';
import styles from './styles.module.css';
import {
  userSelector,
  getAllUsers,
  lockUnlockUser
} from 'src/redux/slices/userSlice';
import { useSelector } from 'react-redux';
import PageTitleWrapper from '../PageTitleWrapper';
import AddUserComponent from '../AddUser';
import * as AuthService from 'src/services/authService';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { User } from 'src/models/user';
import { deleteUser } from 'src/redux/slices/userSlice';
import { sortOptions } from './sortOption';
import DeleteConfirmationComponent from '../DeleteConfirmation';
import ChangePasswordComponent from '../ChangePassword';
import SearchIcon from '@mui/icons-material/Search';

const UserListComponent: React.FC<{ admins?: boolean }> = ({
  admins = false
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(15);
  const [open, setOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('firstName');
  const [search, setSearch] = useState<string>('');
  const [deletedUser, setDeletedUser] = useState<User>(null);
  const [editedUser, setEditedUser] = useState<User>(null);
  const [deleteConfirmation, setdeleteConfirmation] = useState<boolean>(false);
  const [changedLocked, setChangedLocked] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenChangePassword = () => setOpenChangePassword(true);
  const handleCloseChangePassword = () => setOpenChangePassword(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const allUsers = useSelector(userSelector.allUsers);

  const acc = AuthService.getAccList();
  const canManageUsers = ['company_users', 'admin'];
  const hasUserActions = acc.some((e) => canManageUsers.includes(e));
  const isAdmin = acc.includes('admin');

  const deleteItem = async (email) => {
    const response = await dispatch(deleteUser(email));
    if (response.meta.requestStatus === 'fulfilled') {
      setdeleteConfirmation(false);
    }
  };

  function addItem(): void {
    setEditedUser(null);
    handleOpen();
  }

  function editItem(user: User): void {
    setEditedUser(user);
    handleOpen();
  }

  function changePassword(user: User): void {
    setEditedUser(user);
    handleOpenChangePassword();
  }

  const openDeleteConfirmation = async (e, user) => {
    setDeletedUser(user);
    setdeleteConfirmation(true);
  };
  const closeDeleteConfirmation = async (e) => {
    setdeleteConfirmation(false);
  };

  const changeLocked = async (e, user: User) => {
    const response = await dispatch(lockUnlockUser(user));
    if (response.meta.requestStatus === 'fulfilled') {
      setChangedLocked(!changedLocked);
    }
  };

  const searchUser = async (e) => {
    setPage(0);
    setSearch(e.target.value);
    const query = e.target.value;
    dispatch(
      getAllUsers({ page, pageSize: limit, sortBy, search: query, admins })
    );
  };

  const searchUserDebounced = debounce(searchUser, 300);

  useEffect(() => {
    if (!open && !deleteConfirmation) {
      dispatch(getAllUsers({ page, pageSize: limit, sortBy, search, admins }));
    }
  }, [limit, page, open, deleteConfirmation, sortBy, changedLocked, admins]);

  useEffect(() => {
    setSearch('');
  }, []);

  return (
    <>
      <Helmet>
        <title>Korisnici</title>
      </Helmet>
      <PageTitleWrapper>
        <DeleteConfirmationComponent
          isOpen={deleteConfirmation}
          handleConfirm={deleteItem}
          handleClose={closeDeleteConfirmation}
          deletedId={deletedUser ? deletedUser.id : null}
        >
          Da li ste sigurni da želite da obrišete korisnika sa imenom:
          <b>
            {' '}
            {deletedUser
              ? deletedUser.firstName + ' ' + deletedUser.lastName
              : null}
          </b>
          ?
        </DeleteConfirmationComponent>

        <Grid container justifyContent="space-between" alignItems="end">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {admins ? 'Administratori' : 'Korisnici'}
            </Typography>
            {!isAdmin && (
              <span>
                {' '}
                <Chip
                  label={`Preostali broj licenci: ${allUsers.remainingLicenses}`}
                  color={allUsers.remainingLicenses ? 'success' : 'error'}
                  // variant="outlined"
                />{' '}
              </span>
            )}
          </Grid>
          <Grid item>
            {hasUserActions && !admins && (
              <>
                <Button
                  sx={{ mt: { xs: 2, md: 0 } }}
                  variant="contained"
                  startIcon={<AddTwoToneIcon fontSize="small" />}
                  onClick={addItem}
                >
                  dodaj korisnika
                </Button>
                {open && (
                  <AddUserComponent
                    editedUser={editedUser}
                    isOpen={open}
                    handleCloseModule={handleClose}
                    admins={admins}
                  />
                )}
              </>
            )}
            <ChangePasswordComponent
              editedUser={editedUser}
              isOpen={openChangePassword}
              handleCloseModule={handleCloseChangePassword}
            />
          </Grid>
        </Grid>
      </PageTitleWrapper>
      {!admins && (
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  action={
                    <Box width={250}>
                      <FormControl>
                        <InputLabel htmlFor="search-field">Pretraži</InputLabel>
                        <OutlinedInput
                          id="search-field"
                          fullWidth
                          onChange={searchUserDebounced}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Pretraži"
                        />
                      </FormControl>
                    </Box>
                  }
                  title={
                    <Box width={200}>
                      <TextField
                        select
                        label="Sortiraj po"
                        onChange={(e) => {
                          setSortBy(e.target.value);
                        }}
                        defaultValue={sortOptions[0].value}
                        fullWidth
                      >
                        {sortOptions.map((option) => {
                          return (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Box>
                  }
                />
                <Divider />
                <TableContainer className={styles.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Ime i prezime</TableCell>
                        <TableCell>Rola</TableCell>
                        {isAdmin && <TableCell>Kompanija</TableCell>}
                        <TableCell align="right">Ažuriraj Korisnika</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allUsers?.data ? (
                        allUsers.data.map((user: User) => {
                          return (
                            <TableRow hover key={user.id}>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {user.email}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {user.firstName} {user.lastName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                >
                                  {user.type}
                                </Typography>
                              </TableCell>
                              {isAdmin && (
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    color="text.primary"
                                    gutterBottom
                                    noWrap
                                  >
                                    {user.companyName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondery"
                                    gutterBottom
                                    noWrap
                                  >
                                    {user.companyPIB}
                                  </Typography>
                                </TableCell>
                              )}
                              <TableCell align="right">
                                {AuthService.getEmail() !== user.email && (
                                  <>
                                    <Tooltip title="Promenite lozinku" arrow>
                                      <IconButton
                                        sx={{
                                          '&:hover': {
                                            background:
                                              theme.colors.primary.lighter
                                          },
                                          color: theme.palette.primary.main
                                        }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) => changePassword(user)}
                                      >
                                        <VpnKeyIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Izmeni korisnika" arrow>
                                      <IconButton
                                        sx={{
                                          '&:hover': {
                                            background:
                                              theme.colors.primary.lighter
                                          },
                                          color: theme.palette.primary.main
                                        }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) => editItem(user)}
                                      >
                                        <EditTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title={
                                        user.locked
                                          ? 'Otključaj korisnika'
                                          : 'Zaključaj korisnika'
                                      }
                                      arrow
                                    >
                                      <IconButton
                                        sx={{
                                          '&:hover': {
                                            background:
                                              theme.colors.warning.lighter
                                          },
                                          color: !user.newUser
                                            ? !user.locked
                                              ? theme.palette.success.main
                                              : theme.palette.error.main
                                            : theme.palette.warning.light
                                        }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) => changeLocked(e, user)}
                                      >
                                        {!user.locked ? (
                                          <LockOpenIcon fontSize="small" />
                                        ) : (
                                          <LockIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Obriši korisnika" arrow>
                                      <IconButton
                                        sx={{
                                          '&:hover': {
                                            background:
                                              theme.colors.error.lighter
                                          },
                                          color: theme.palette.error.main
                                        }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) =>
                                          openDeleteConfirmation(e, user)
                                        }
                                      >
                                        <DeleteTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box p={2}>
                  <TablePagination
                    component="div"
                    count={Number(allUsers.totalCount)}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setLimit(parseInt(e.target.value));
                      setPage(0);
                    }}
                    labelRowsPerPage="Broj redova po stranici:"
                    page={page}
                    rowsPerPage={limit}
                    labelDisplayedRows={({ from, to, count, page }) =>
                      `od ${from} do ${to}, ukupno: ${count}`
                    }
                    rowsPerPageOptions={[5, 15, 30, 45, 60, 75, 100]}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default UserListComponent;
