import { Navigate, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { authSelector } from 'src/redux/slices/authSlice';
import { getAccList } from 'src/services/authService';

export const NonAuthRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const { isAuthenticated } = useSelector(authSelector.auth);
  const acc = getAccList();
  const dashboard = acc.includes('admin') ? '/admin/companies' : '/';
  if (isAuthenticated) {
    return <Navigate to={dashboard} state={{ from: location }} />;
  }

  return children;
};
