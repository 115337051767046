import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import companyReducer from './slices/companySlice';
import searchReducer from './slices/searchSlice';
import historyReducer from './slices/historySlice';
import clientReducer from './slices/clientSlice';
import adminReducer from './slices/adminSlice';
import { useDispatch } from 'react-redux';

const store = configureStore({
  reducer: {
    user: userReducer,
    company: companyReducer,
    auth: authReducer,
    search: searchReducer,
    history: historyReducer,
    client: clientReducer,
    admin: adminReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
