import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ClientPerson, ClientCompany, UpdateDocument } from 'src/models/client';
import * as ClientService from '../../services/clientService';
import { TableOptionsState } from 'src/utils/useTablePagination';

const SLICE_NAME = 'client';

const initialState = {
  allPersonClients: {
    data: [] as ClientPerson[],
    totalCount: 0
  },
  allCompanyClients: {
    data: [] as ClientCompany[],
    totalCount: 0
  },
  allClients: [],
  clientRelations: [],
  clientCompanyForm: [],
  clientPerson: {} as ClientPerson,
  clientCompany: {} as ClientCompany,
  clientDocuments: [],
  clientAttachements: [],
  updateDocument: {} as UpdateDocument,
  pending: false,
  searchResult: [],
  addRiskMatrix: {}
};

export const getAllPersonClients = createAsyncThunk(
  'clients/person/clients-list/get',
  async (data: {
    options: TableOptionsState;
    clientType: number;
    search: string;
  }) => {
    const res = await ClientService.allPersonClients(data);
    return res;
  }
);

export const getAllCompanyClients = createAsyncThunk(
  'clients/company/clients-list/get',
  async (data: {
    options: TableOptionsState;
    clientType: number;
    search: string;
  }) => {
    const res = await ClientService.allCompanyClients(data);
    return res;
  }
);

export const getClientsSearchHistory = createAsyncThunk(
  'clients/clients-search-history/get',
  async (clientId: number) => {
    const res = await ClientService.getClientsSearchHistory(clientId);
    return res;
  }
);

export const getClientsConnectedEntities = createAsyncThunk(
  'clients/clients-connected-entities/get',
  async (clientId: number) => {
    const res = await ClientService.getClientsConnectedEntities(clientId);
    return res;
  }
);

export const changeIsSanctioned = createAsyncThunk(
  'clients/change-is-sanctioned/put',
  async (data: { id: number; isSanctioned: number }) => {
    const res = await ClientService.changeIsSanctioned(data);
    return res;
  }
);

export const getClientsDropdown = createAsyncThunk(
  'clients/clients-dropdown/get',
  async () => {
    const res = await ClientService.getClientsDropdown();
    return res;
  }
);

export const addClient = createAsyncThunk(
  '/clients/create-client/post',
  async (data: ClientPerson) => {
    const res = await ClientService.addClient(data);
    return res;
  }
);

export const updateClient = createAsyncThunk(
  '/clients/update-client/put',
  async ({ data, id }: { data: any; id: number }) => {
    const res = await ClientService.updateClient(data, id);
    return res;
  }
);

export const getClientPerson = createAsyncThunk(
  'client/person/get',
  async (clientId: number) => {
    const res = await ClientService.getClientPerson(clientId);
    return res;
  }
);

export const getClientCompany = createAsyncThunk(
  'clients/client/get',
  async (clientId: number) => {
    const res = await ClientService.getClientCompany(clientId);
    return res;
  }
);

export const addClientConnectedEntities = createAsyncThunk(
  'client/client-connected-entity/post',
  async (data: any) => {
    const res = await ClientService.addClientConnectedEntities(data);
    return res;
  }
);

export const deleteClientConnectedEntity = createAsyncThunk(
  //nije korisceno
  'clients/client-connected-entity/delete',
  async (id: number) => {
    const res = await ClientService.deleteClientConnectedEntity(id);
    return res;
  }
);

export const updateClientConnectedEntity = createAsyncThunk(
  'clients/update-client-connected-entity/put',
  async ({ id, data }: { id: number; data: any }) => {
    const res = await ClientService.updateClientConnectedEntity(id, data);
    return res;
  }
);

export const addClientDocument = createAsyncThunk(
  'client/doc-client/location/post',
  async (data: any) => {
    const id = data.userId;
    const payload = {
      location: data.location,
      locationName: data.locationName
    };
    const res = await ClientService.addClientDocument(id, payload);
    return res;
  }
);

export const addClientAttachments = createAsyncThunk(
  'clients/add-document/post',
  async (data: any) => {
    const res = await ClientService.addClientAttachments(data);
    return res;
  }
);

export const deleteClientDocument = createAsyncThunk(
  'clients/delete-client-document/delete',
  async (clientDocumentId: number) => {
    const res = await ClientService.deleteClientDocument(clientDocumentId);
    return res;
  }
);

export const updateClientDocument = createAsyncThunk(
  'clients/update-client-document/put',
  async ({
    clientDocumentId,
    data
  }: {
    clientDocumentId: number;
    data: UpdateDocument;
  }) => {
    const res = await ClientService.updateClientDocument(
      clientDocumentId,
      data
    );
    return res;
  }
);

export const startSearchClient = createAsyncThunk(
  'clients/add-client-search-history/post',
  async (data: any) => {
    const res = await ClientService.startSearchClient(data);
    return res;
  }
);

export const addRiskMatrix = createAsyncThunk(
  'clients/add-client-risk-matrix/post',
  async (data: any) => {
    const res = await ClientService.addRiskMatrix(data);
    return res;
  }
);

export const updateRiskMatrix = createAsyncThunk(
  'clients/update-client-risk-matrix/put',
  async ({ data, id }: { data: any; id: number }) => {
    const res = await ClientService.updateRiskMatrix({ data, id });
    return res;
  }
);

export const getRiskMatrix = createAsyncThunk(
  'clients/get-client-risk-matrix/get',
  async (id: number) => {
    const res = await ClientService.getRiskMatrix(id);
    return res;
  }
);

const pendingMethod = (state) => {
  state.pending = true;
};

const clientSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [getClientsConnectedEntities.pending.toString()]: pendingMethod,
    [getClientsConnectedEntities.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getClientsConnectedEntities.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getClientsSearchHistory.pending.toString()]: pendingMethod,
    [getClientsSearchHistory.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getClientsSearchHistory.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [startSearchClient.pending.toString()]: pendingMethod,
    [startSearchClient.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [startSearchClient.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addRiskMatrix.pending.toString()]: pendingMethod,
    [addRiskMatrix.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [addRiskMatrix.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateRiskMatrix.pending.toString()]: pendingMethod,
    [updateRiskMatrix.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateRiskMatrix.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getRiskMatrix.pending.toString()]: pendingMethod,
    [getRiskMatrix.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getRiskMatrix.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateClientConnectedEntity.pending.toString()]: pendingMethod,
    [updateClientConnectedEntity.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateClientConnectedEntity.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteClientConnectedEntity.pending.toString()]: pendingMethod,
    [deleteClientConnectedEntity.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteClientConnectedEntity.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClientConnectedEntities.pending.toString()]: pendingMethod,
    [addClientConnectedEntities.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClientConnectedEntities.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllPersonClients.pending.toString()]: pendingMethod,
    [getAllPersonClients.fulfilled.toString()]: (state, action) => {
      state.allPersonClients = action.payload;
      state.pending = false;
    },
    [getAllPersonClients.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteClientDocument.pending.toString()]: pendingMethod,
    [deleteClientDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteClientDocument.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllCompanyClients.pending.toString()]: pendingMethod,
    [getAllCompanyClients.fulfilled.toString()]: (state, action) => {
      state.allCompanyClients = action.payload;
      state.pending = false;
    },
    [getAllCompanyClients.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getClientsDropdown.pending.toString()]: pendingMethod,
    [getClientsDropdown.fulfilled.toString()]: (state, action) => {
      state.allClients = action.payload;
      state.pending = false;
    },
    [getClientsDropdown.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClient.pending.toString()]: pendingMethod,
    [addClient.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClient.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateClient.pending.toString()]: pendingMethod,
    [updateClient.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateClient.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClientAttachments.pending.toString()]: pendingMethod,
    [addClientAttachments.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClientAttachments.rejected.toString()]: (state) => {
      state.pending = false;
    },
    [getClientPerson.pending.toString()]: pendingMethod,
    [getClientPerson.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getClientPerson.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getClientCompany.pending.toString()]: pendingMethod,
    [getClientCompany.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getClientCompany.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClientDocument.pending.toString()]: pendingMethod,
    [addClientDocument.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addClientDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateClientDocument.pending.toString()]: pendingMethod,
    [updateClientDocument.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [updateClientDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    }
  }
});

const { reducer } = clientSlice;
export default reducer;

const getAppState = (state) => state[SLICE_NAME];

export const clientSelector = {
  pending: (rootState) => {
    const appState = getAppState(rootState);
    return appState.pending;
  },
  allPersonClients: (rootState) => {
    const appState = getAppState(rootState);
    return appState.allPersonClients;
  },
  allCompanyClients: (rootState) => {
    const appState = getAppState(rootState);
    return appState.allCompanyClients;
  },
  allClients: (rootState) => {
    const appState = getAppState(rootState);
    return appState.allClients;
  },
  clientRelations: (rootState) => {
    const appState = getAppState(rootState);
    return appState.clientRelations;
  },
  clientCompany: (rootState) => {
    const appState = getAppState(rootState);
    return appState.clientCompany;
  },
  clientPerson: (rootState) => {
    const appState = getAppState(rootState);
    return appState.clientPerson;
  },
  updateDocument: (rootState) => {
    const appState = getAppState(rootState);
    return appState.updateDocument;
  },
  clientDocuments: (rootState) => {
    const appState = getAppState(rootState);
    return appState.clientDocuments;
  },
  addRiskMatrix: (rootState) => {
    const appState = getAppState(rootState);
    return appState.addRiskMatrix;
  }
};
