import { Button, Hidden, Typography } from '@mui/material';
import React from 'react';
import style from './styles.module.css';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { logout } from 'src/redux/slices/authSlice';
import { useAppDispatch } from 'src/redux';
import ChangePassword from '../../Header/Buttons/ChangePassword';
import { getAccList } from 'src/services/authService';
import ApiSettings from '../../Header/Buttons/ApiSettings';

const BottomMenue = () => {
  const dispatch = useAppDispatch();
  const signOut = () => {
    dispatch(logout());
  };
  const [show, setShow] = React.useState(false);
  const [showApi, setShowApi] = React.useState(false);
  const accList = getAccList();

  const hasApiStettings = accList.includes('company_settings') && accList.includes('search');

  return (
    <div className={style.postionStyle}>
      <Hidden lgUp>
      {hasApiStettings && (
          <Button
          color="primary"
          className={style.button}
          onClick={() => setShowApi(true)}
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="outlined"
          startIcon={<ExitToAppIcon fontSize="small" />}
        >
          API podešavanja opcija
        </Button>
        )}
        <Button
          color="primary"
          className={style.button}
          onClick={() => setShow(true)}
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="outlined"
          startIcon={<ExitToAppIcon fontSize="small" />}
        >
          Promenite lozinku
        </Button>
        <Button
          color="primary"
          className={style.button}
          onClick={signOut}
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="outlined"
          startIcon={<ExitToAppIcon fontSize="small" />}
        >
          Odjava
        </Button>
      </Hidden>
      <div className={style.footer}>
        <Typography variant="subtitle2">&copy; 2023 - AML Sonar</Typography>
      </div>
      <ChangePassword show={show} close={() => setShow(false)} />
      <ApiSettings show={showApi} close={() => setShowApi(false)} />
    </div>
  );
};

export default BottomMenue;
