import axios from 'axios';
import { config } from '../config';

const AxiosInstance = axios.create({
  baseURL: config.URL,
  timeout: 5000
});

AxiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = localStorage.getItem('token');
  if (token) {
    const bearer = `Bearer ${token}`;
    config.headers['Authorization'] = bearer;
  }
  return config;
});

//Response interceptor is moved to App.tsx because of using dispatch

export default AxiosInstance;
