import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { ServerError } from 'src/models/errors/serverError';

import * as AuthService from '../../services/authService';

const SLICE_NAME = 'auth';
const token = localStorage.getItem('token');
const initialState = {
  auth: {
    currentUser: token ? jwt_decode(token) : null,
    isAuthenticated: token ? true : false,
    loading: false
  }
};

export const login = createAsyncThunk('authenticate', async (data: any) => {
  const res = await AuthService.login(data);
  return res;
});

const authSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    logout: (state) => {
      state.auth = {
        currentUser: null,
        isAuthenticated: false,
        loading: false
      };
      localStorage.removeItem('token');
    }
  },
  extraReducers: {
    [login.pending.toString()]: (state, action) => {
      state.auth.loading = true;
    },
    [login.rejected.toString()]: (state, action: ServerError) => {
      state.auth.loading = false;
    },
    [login.fulfilled.toString()]: (state, action) => {
      state.auth = {
        loading: false,
        currentUser: jwt_decode(action.payload.jwt),
        isAuthenticated: true
      };
      localStorage.setItem('token', action.payload.jwt);
    }
  }
});

export const { logout } = authSlice.actions;

const { reducer } = authSlice;
export default reducer;

const getAppState = (state) => state[SLICE_NAME];

export const authSelector = {
  auth: (rootState) => {
    const appState = getAppState(rootState);
    return appState.auth;
  }
};
