import axiosInstance from 'src/utils/axiosInstance';
import { TableOptionsState } from 'src/utils/useTablePagination';

export const add = async (data: any) => {
  const response = await axiosInstance.post('/admin/company', data);
  return response.data;
};
export const edit = async (data: any) => {
  const response = await axiosInstance.patch('/admin/company', data);
  return response.data;
};
export const getAll = async (
  page: number,
  pageSize: number,
  sortBy: string,
  search: string,
  industryId: string,
  licenseId: string
) => {
  const response = await axiosInstance.get(
    `/admin/company?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&industryId=${industryId}&licenseId=${licenseId}${
      search ? '&search=' + search : ''
    }`
  );
  return { data: response.data.content, totalCount: response.data.total };
};
export const deleteCompany = async (id: number) => {
  const response = await axiosInstance.delete(`/admin/company/${id}`);
  return response.status;
};

export const getAdminTemplateDocuments = async ({
  options,
  folder
}: {
  options: TableOptionsState;
  folder: number;
}) => {
  const response = await axiosInstance.get(
    `/admin/list-document-templates?page=${options.pagination.page}&pageSize=${options.pagination.pageSize}&folder=${folder}`
  );
  return { data: response.data.content, totalCount: response.data.total };
};

export const addTemplateDocument = async (data) => {
  const response = await axiosInstance.post(
    `/admin/upload-document-template`,
    data
  );
  return response.data;
};

export const editTemplateDocument = async (data: any, id: number) => {
  const response = await axiosInstance.put(
    `/documents/update-document-template/${id}`,
    data
  );
  return response.data;
};

export const deleteTemplateDocument = async (id: number) => {
  await axiosInstance.delete(`/documents/delete-document-template/${id}`);
};

export const addInternalDocument = async (data: any) => {
  const response = await axiosInstance.post('/admin/upload-internal', data);
  return response.data;
};

export const addClientDocument = async (data: any) => {
  const response = await axiosInstance.post('/admin/upload-client', data);
  return response.data;
};

export const addGeneralDocument = async (data: any) => {
  const response = await axiosInstance.post('/admin/upload-general', data);
  return response.data;
};

export const getAllSingleUsers = async (
  page: number,
  pageSize: number,
  sortBy: string,
  search: string,
  licenseId: string
) => {
  const response = await axiosInstance.get(
    `/admin/single-user?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&licenseId=${licenseId}${
      search ? '&search=' + search : ''
    }`
  );
  return { data: response.data.content, totalCount: response.data.total };
};

export const editSingle = async (data: any) => {
  const response = await axiosInstance.patch('/admin/single-user', data);
  return response.data;
};

export const lockUnlockCompany = async (data: any) => {
  let status;
  data.locked === 0 ? (status = 1) : (status = 0);
  const response = await axiosInstance.post(
    `/admin/company/status/${data.id}`,
    { locked: status }
  );
  return response.status;
};

export const changeApiStatus = async (data: any) => {
  const response = await axiosInstance.post(`/admin/company/api/${data.id}`, {
    status: data.locked
  });
  return response.data;
};

export const getApiKeyAdmin = async (id: string) => {
  const response = await axiosInstance.get(`/admin/company/api/${id}`);
  return response.data;
};

export const renewApiKey = async (id: string) => {
  const response = await axiosInstance.get(`/admin/company/api/renew/${id}`);
  return response.data;
};
