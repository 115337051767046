import React from 'react';
import { Box, Button, Menu, MenuItem, Hidden } from '@mui/material';
import { logout } from 'src/redux/slices/authSlice';
import { useAppDispatch } from 'src/redux';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { getFullName, getAccList } from 'src/services/authService';
import ChangePassword from './ChangePassword';
import ApiSettings from './ApiSettings';

function HeaderButtons() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [showApi, setShowApi] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  const signOut = () => {
    dispatch(logout());
  };

  const fullName = getFullName();
  const accList = getAccList();

  const hasApiStettings = accList.includes('company_settings') && accList.includes('search');

  return (
    <Box sx={{ mr: 1 }}>
      <Hidden lgDown>
        <Button
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AccountCircle fontSize="medium" style={{ paddingRight: '5pt' }} />
          <span>{fullName ?? 'NN Lice'}</span>
        </Button>
      </Hidden>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setShow(true)} sx={{ textAlign: 'center' }}>
          <VpnKeyIcon fontSize="small" sx={{ marginRight: 1 }}>
            <Logout fontSize="small" />
          </VpnKeyIcon>
          Promenite lozinku
        </MenuItem>

        {hasApiStettings && (
          <MenuItem onClick={() => setShowApi(true)}>
            <SettingsIcon fontSize="small" sx={{ marginRight: 1 }}>
              <Logout fontSize="small" />
            </SettingsIcon>
            API podešavanja opcija
          </MenuItem>
        )}

        <MenuItem onClick={signOut}>
          <ListItemIcon sx={{ marginRight: -1 }}>
            <Logout fontSize="small" />
          </ListItemIcon>
          Odjava
        </MenuItem>
      </Menu>
      <ChangePassword show={show} close={() => setShow(false)} />
      <ApiSettings show={showApi} close={() => setShowApi(false)} />
    </Box>
  );
}

export default HeaderButtons;
